import { useMutation } from '@apollo/client/index.js';
import PhaseForm, { FormValues, validate } from '@asaprint/asap/components/forms/PhaseForm.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { BUTTON_CREATE } from '@asaprint/asap/locales/client.js';
import { ADMIN_PHASE_GROUPS_ROUTE, ADMIN_PHASES_ROUTE, DASHBOARD_ROUTE } from '@asaprint/asap/routes.js';
import { NewPhase_Save } from '@asaprint/asap/routes/__authenticated/admin/phases/create.graphql';
import { NewPhase_SaveMutation, NewPhase_SaveMutationVariables } from '@asaprint/asap/schema.client.types.js';
import { Permission } from '@asaprint/common/access.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { ConnectedSubmitButtons } from '@engined/client/components/SubmitButtons.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const logger = getLogger('@asap/routes/admin/phases/create');
interface OwnProps {}

type Props = OwnProps;

const initialValues: FormValues = {
  name: '',
  color: '#000000',
  group: null,
  calculatorName: '',
};

const Page: React.FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const { t } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const [saveExecute] = useMutation<NewPhase_SaveMutation, NewPhase_SaveMutationVariables>(NewPhase_Save);

  const onSubmit: OnSubmit<FormValues> = async ({ group, color, ...values }: FormValues) => {
    try {
      const response = await saveExecute({
        variables: {
          input: {
            ...values,
            color: color.slice(1), // keep only value without #
            group: group?.value ?? null,
          },
        },
      });
      enqueueSnackbar(
        <Box>
          Úspešne ste vytvorili novú fázu <strong>{response.data.phaseSave.name}</strong> produktu.
        </Box>,
        {
          variant: 'success',
        },
      );
      navigate(ADMIN_PHASES_ROUTE, { state: { ignoreFormPrompt: true } });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faLayerGroup} /> Nová skupina pre fázy produktu
          </>
        }
      />

      <PagePaper>
        <Form defaultValues={initialValues} onSubmit={onSubmit} validate={validate}>
          <PhaseForm />
          <ConnectedSubmitButtons submitLabel={t(BUTTON_CREATE)} backTo={ADMIN_PHASE_GROUPS_ROUTE} />
        </Form>
      </PagePaper>
    </>
  );
};

export default authorized(Permission.PhasesManage)(Page);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  return null;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Administrácia',
    },
    {
      text: 'Fázy produktu',
      to: ADMIN_PHASES_ROUTE,
    },
    {
      text: 'Nová fáza',
    },
  ],
  meta: ({ meta }: MetaFunctionArgs) => ({
    title: `Nová fáza | Fázy produktu | Administrácia | ${meta.title}`,
  }),
};
