
            import createOneQuery from '@engined/core/build/graphql-plugin-lib';
            
            const doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"NewPhase_Phase"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Phase"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"position"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}},{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"NewPhase_Save"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PhaseInput"}}},"directives":[]}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"phaseSave"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"NewPhase_Phase"},"directives":[]}]}}]}}],"loc":{"start":0,"end":208}};
            doc.loc.source = {"body":"\n          fragment NewPhase_Phase on Phase {\n  id\n  createdAt\n  updatedAt\n  position\n  name\n}\n\nmutation NewPhase_Save($input: PhaseInput!) {\n  phaseSave(input: $input) {\n    ...NewPhase_Phase\n  }\n}\n\n        ","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
          

    const names = new Set<string>();
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          const name = def.name.value
          if (names.has(name)) {
            return false;
          } else {
            names.add(name);
            return true;
          }
        }
      )
    }
  

              const oneQuery = createOneQuery(doc);
              export default doc;
            
                  export const NewPhase_Phase = oneQuery(doc, "NewPhase_Phase");
                
                  export const NewPhase_Save = oneQuery(doc, "NewPhase_Save");
                
